import { ArrowLeftIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';

export const ToolbarMarketing = () => {
    const navigate=useNavigate()
  return (
    <>
      <div className="relative flex items-center gap-2 space-x-3">
        <Tooltip content="Ir a la pagina anterior" color="primary">
          <span onClick={()=>navigate('/marketing/')} className="text-lg flex text-default-400 cursor-pointer active:opacity-50 hover:text-secondary text-secondary/70 p-1" >
            <ChevronLeftIcon className="h-5 w-5 mt-1 mr-2 " />
            <span className="pb-2 ">Volver</span>
          </span>
        </Tooltip>
      </div>
    </>
  );
};
