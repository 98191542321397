import React from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  cn,
} from "@nextui-org/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { GmailIcon } from "../../../../assets/icons/GmailIcon";
import { useNavigate } from "react-router-dom";

export const ToolbarMensajes = () => {

  const navigate = useNavigate()

  const handleAction=(e:any)=>{    
    navigate(`/marketing/${e}`)
  }

  return (
    <>
      <div className="flex flex-row-reverse">
        <Dropdown backdrop="blur">
          <DropdownTrigger>
            <Button color="primary">
              REDACTAR <PlusIcon className="h-6 w-6" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            variant="faded"
            aria-label="Dropdown menu with icons"
            color="primary"
            onAction={(key) => handleAction(key)}
          >
            <DropdownItem
              key="correo"
              endContent={<GmailIcon className="h-5 w-5" />}
            >
              Correo
            </DropdownItem>
            <DropdownItem
              key="mensajes"
              endContent={
                <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" />
              }
            >
              Mensajes
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};
