import { Button, Select, SelectItem, Textarea } from "@nextui-org/react";
import {
  metodoDeEnvio,
  opcionesOportunidadOriginDestino,
  opcionesOportunidadPerson,
  opcionesOportunidadService,
} from "../../../data/opcionesOportunidad";
import { CountryIcon } from "../../../../assets/icons/CountryIcon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { PromesaToast } from "../../../../helpers/NotificacionToast";
import { prospectosApi } from "../../../../api/prospectosApi";
import { useMemo, useReducer } from "react";

const schema = yup
  .object({
    message_via: yup.string().required("Seleccionar la via del mensaje"),
    message: yup.string().required("No puedes enviar mensajes vacios"),
    service: yup.string().required("Seleccionar el tipo de servicio"),
    origin: yup.string().required("Seleccionar el origen"),
    prospect: yup.string().required("Debe seleccionar el tipo de cliente"),
  })
  .required();

export const FormMensajes = ({ type }: { type: number }) => {
  const filtradoMetodo = useMemo(() => {
    if (type == 3) {
      return metodoDeEnvio.filter((d) => d.id == 3);
    }
    return metodoDeEnvio.filter((d) => d.id !== 3);
  }, [type]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    const body = {
      massive: true,
      issue: "Mensaje masivo",
      message: data.message,
      message_via: data.message_via,
      client_parameters: {
        service: [data.service],
        origin: [data.origin],
        prospect: [data.prospect == "2"],
      },
    };
    const { success, error } = PromesaToast();
    prospectosApi
      .post("/api/services/messages/", body)
      .then((result) => {
        if (result.status == 200) {
          reset();
          success("Mensaje enviado con exito");
        }
      })
      .catch((err) => {
        if (err.response.status == 404) {
          return error("No hay cliente para del pais seleccionado");
        }

        error("Error al enviar el mensaje");
      });
  };

  return (
    <div className="bg-white">
      <div className="flex item-center ml-5">
        <p className="max-w-lg mt-3 -mb-3 text-3xl font-semibold leading-normal text-gray-900 ">
          Detalle de mensaje
        </p>
      </div>
      <form className="rounded-lg shadow-lg" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <div className="">
            <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6  p-8 mt-2">
              <div className="col-span-full">
                <label htmlFor="" className="font-semibold">
                  Metodo de mensaje
                </label>
                <Select
                  className=""
                  labelPlacement="outside"
                  placeholder="Selecciona el tipo de cliente"
                  color="secondary"
                  variant="bordered"
                  isInvalid={!!errors.message_via}
                  errorMessage={
                    errors.message_via && "Debes seleccionar el metodo de envio"
                  }
                  {...register("message_via")}
                >
                  {filtradoMetodo.map((d) => (
                    <SelectItem key={d.id
                    } value={d.id}>
                      {d.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="col-span-full">
                <label htmlFor="" className="font-semibold">
                  Tipo de clientes
                </label>
                <Select
                  className=""
                  labelPlacement="outside"
                  placeholder="Selecciona el tipo de cliente"
                  color="secondary"
                  variant="bordered"
                  isInvalid={!!errors.prospect}
                  errorMessage={
                    errors.prospect && "Debes seleccionar el tipo de cliente"
                  }
                  {...register("prospect")}
                >
                  {opcionesOportunidadPerson.map((items) => (
                    <SelectItem key={items.id} value={items.name}>
                      {items.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>

              <div className="col-span-full">
                <label htmlFor="" className="font-semibold">
                  Tipo de servicio
                </label>
                <Select
                  className=""
                  color="secondary"
                  placeholder="Selecciona el tipo de servicio"
                  variant="bordered"
                  labelPlacement="outside"
                  isInvalid={!!errors.service}
                  errorMessage={
                    errors.prospect && "Debes seleccionar el tipo de servicio"
                  }
                  {...register("service")}
                >
                  {opcionesOportunidadService.map((items) => (
                    <SelectItem key={items.value} value={items.name}>
                      {items.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="col-span-full">
                <label htmlFor="" className="font-semibold">
                  Origen
                </label>
                <Select
                  items={opcionesOportunidadOriginDestino}
                  placeholder="Selecciona el origen"
                  className="w-full"
                  variant="bordered"
                  aria-label="origen"
                  color="secondary"
                  size="md"
                  isInvalid={!!errors.origin}
                  errorMessage={errors.origin?.message}
                  {...register("origin")}
                >
                  {(pais: any) => (
                    <SelectItem
                      key={pais.name}
                      startContent={
                        <CountryIcon country={pais.name} className="h-5 w-5" />
                      }
                    >
                      {pais.name}
                    </SelectItem>
                  )}
                </Select>
              </div>
              <div className="col-span-full">
                <label htmlFor="" className="font-semibold">
                  Mensaje
                </label>
                <Textarea
                  placeholder="Introduce el mensaje"
                  color="secondary"
                  variant="bordered"
                  isInvalid={!!errors.message}
                  errorMessage={
                    errors.message && "No se puede enviar mensajes vacios"
                  }
                  {...register("message")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6 pb-5 pr-8">
          <Button color="secondary" type="submit">
            ENVIAR
            <PaperAirplaneIcon className="h-5 w-5" />
          </Button>
        </div>
      </form>
    </div>
  );
};
