import { createSlice } from "@reduxjs/toolkit";

export interface initialModalMensajeria {
  statusCorreo: boolean;
  statusSeguimiento: boolean;
}

const initialState: initialModalMensajeria = {
  statusCorreo: false,
  statusSeguimiento: false,
};

export const modalMensajeria = createSlice({
  name: "modalMensajeria",
  initialState,
  reducers: {
    mostrarModalCorreo: (state) => {
      state.statusCorreo = true;
    },
    ocultarModalCorreo: (state) => {
      state.statusCorreo = false;
    },
    mostrarModalSeguimiento: (state) => {
      state.statusSeguimiento = true;
    },
    ocultarModalSeguimiento: (state) => {
      state.statusSeguimiento = false;
    },
  },
});

export const {
  mostrarModalCorreo,
  ocultarModalCorreo,
  mostrarModalSeguimiento,
  ocultarModalSeguimiento,
} = modalMensajeria.actions;
