import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketingAsync } from "../../../../store/marketing/thunks";
import { SelectorInterface } from "../../../../interfaces/SelectorInterface";
import { formatearFecha } from "../../../../helpers/Formateos";
import { PaginatorMensajes } from "./PaginatorMensajes";

export const TablaMesajes = () => {
  const { messages, isLoading } = useSelector(
    (d: SelectorInterface) => d.marketing
  );

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchMarketingAsync());
  }, []);

  return (
    <Table
      aria-label="Example static collection table"
      isStriped
      bottomContent={messages.length > 0 ? <PaginatorMensajes /> : null}
    >
      <TableHeader>
        <TableColumn>MENSAJE</TableColumn>
        <TableColumn>DESTINO</TableColumn>
        <TableColumn>FECHA</TableColumn>
        <TableColumn>VIA</TableColumn>
      </TableHeader>
      <TableBody
        items={messages}
        isLoading={isLoading}
        emptyContent={"No hay mensajes para mostrar"}
      >
        {(item) => (
          <TableRow key={item.id}>
            <TableCell>{item.message}</TableCell>
            <TableCell>{item.origin}</TableCell>
            <TableCell>{formatearFecha(item.created_at)}</TableCell>
            <TableCell>{item.message_via_name}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
