

interface Props {
    
    className?: string;
  }
export const ExitIcon = ({className}:Props) => {
  return (
    <>
        <img src={require('../img/icons8-señal-de-salida-64.png')} className={className} alt="" />
    </>
  )
}
