import { classNames } from "primereact/utils";
import { isMobile } from "react-device-detect";
import { FormMensajes } from "../mensajes/FormMensajes";
import { AlertMensajes } from "../mensajes/AlertMensajes";
import { ToolbarMarketing } from "../components/ToolbarMarketing";

export const CorreoViews = () => {
  return (
    <>
      <ToolbarMarketing />
      <AlertMensajes />
      <div
        className={classNames(
          isMobile ? "grid-cols-1" : "grid-cols-2",
          "grid gap-4 max border-t border-gray-900/10 mt-2 pt-5 flex"
        )}
        style={{ height: "100%" }}
      >
        <div className="">
          <FormMensajes type={3} />
        </div>
        <div className="">
          <img src={require("../../../../assets/img/correoImage.jpg")} />
        </div>
      </div>
    </>
  );
};
