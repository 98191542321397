
import { FormAuth } from "../components/FormAuth";



export const AuthPage = () => {

    

    
    return (
        <>
          <div className="flex min-h-full flex flex-col justify-center px-6 pt-30 md:pt-52 lg:py-52 lg:px-8 bg-primary h-screen">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-10 w-auto"
                src={require("../../assets/img/morden-logo2.png")}
                alt="Your Company"
              />
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-100">
                Ingresar
              </h2>
            </div>
    
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <FormAuth/>
    
              {/* <p className="mt-10 text-center text-sm text-gray-500">
                Derechos reservado 
                
              </p> */}
            </div>
          </div>
        </>
      )
}
