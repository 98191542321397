import { createSlice } from '@reduxjs/toolkit';
import { fetchUsuarioAsync } from './thunks';
import { Usuario } from '../../interfaces/UsuariosInterface';



export interface InitialUsuarioInterfaces {
    isLoading: boolean
    usuario:any[]
    count:number
    page:number
    pageInicial:number|string
    
}

export const initialStateUsuario: InitialUsuarioInterfaces = {
    isLoading: false,
    usuario:[],
    count:0,
    page:0,
    pageInicial:1,
}

export const usuarioSlice = createSlice({
    name: 'usuario',
    initialState:initialStateUsuario,
    reducers: {
        startLoadingUsuario: (state) => {
            state.isLoading = true;
        },
        setPageInicial:(state,{payload})=>{
            state.pageInicial = payload
        },


    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsuarioAsync.pending, (state) => {
                state.isLoading = true;
                state.usuario=[]
            })
            .addCase(fetchUsuarioAsync.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.usuario=payload.results
                state.count=payload.count
                
            });
    },


});

// Action creators are generated for each case reducer function
export const { startLoadingUsuario,setPageInicial } = usuarioSlice.actions;