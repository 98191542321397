import { FaceSmileIcon, UserIcon } from "@heroicons/react/24/solid";
import fondo from '../../../../assets/img/fondoWhatsapp.jpg'

import "./chat.css";


export const ChatMensaje = () => {
  return (
    <>
      <div className="flex-1  justify-between flex flex-col  rounded-lg shadow-lg" style={{ backgroundImage: `url(${fondo})`,height:'100%' }}>
        <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200 bg-verdeWhats rounded-t-lg">
          <div className="relative flex items-center space-x-4 px-4">
            <div className="relative">
              <span className="absolute text-green-500 right-0 bottom-0">
                <svg width="20" height="20">
                  <circle cx="8" cy="8" r="8" fill="currentColor"></circle>
                </svg>
              </span>
              
              <UserIcon className="w-10 sm:w-16 h-10 sm:h-16 rounded-full bg-slate-400 text-gray-200 p-2"/>
            </div>
            <div className="flex flex-col leading-tight">
              <div className="text-2xl mt-1 flex items-center">
                <span className="text-gray-100 mr-3">Mensaje Masivo</span>
              </div>
              <span className="text-lg text-gray-100">España</span>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            
          </div>
        </div>
        <div
          id="messages"
          className="flex flex-col  space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
        >
          <div className="chat-message">
            <div className="flex items-end">
              <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                <div>
                  <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                    Can be verified on any platform using docker
                  </span>
                </div>
              </div>
              {/* <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" className="w-6 h-6 rounded-full order-1"> */}
            </div>
          </div>
          <div className="chat-message">
            <div className="flex items-end justify-end">
              <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                <div>
                  <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">
                    Your error message says permission denied, npm global
                    installs must be given root privileges.
                  </span>
                </div>
              </div>
              {/* <img src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" className="w-6 h-6 rounded-full order-2"> */}
            </div>
          </div>
        </div>
        <div className=" px-4 pt-4 mb-3 sm:mb-3 ">
          <div className="relative flex">
            <span className="absolute inset-y-0 flex items-center">
              <FaceSmileIcon className="w-8 h-8 ml-2 text-gray-500"/>
            </span>
            <input
              type="text"
              placeholder="Mensaje"
              readOnly
              className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-full py-3"
            />
            <div className="items-center inset-y-0 hidden sm:flex ml-4 ">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full px-2 py-3 transition duration-500 ease-in-out text-white bg-verdeWhats hover:bg-blue-400 focus:outline-none "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-6 w-6 ml-2 transform rotate-90"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
