import { isMobile } from "react-device-detect";
import { classNames } from "../../../../helpers/className";
import { ChatMensaje } from "./ChatMensaje";
import { FormMensajes } from "./FormMensajes";
import { AlertMensajes } from "./AlertMensajes";
import { ToolbarMarketing } from "../components/ToolbarMarketing";

const MensajesViews = () => {
  return (
    <>
      <ToolbarMarketing />
      <AlertMensajes />
      <div
        className={classNames(
          isMobile ? "grid-cols-1" : "grid-cols-2",
          "grid gap-4 max border-t border-gray-900/10 mt-2 pt-5 flex"
        )}
        style={{ height: "100%" }}
      >
        <div className="">
          <FormMensajes type={1}/>
        </div>
        {!isMobile && (
          <div className=" ">
            <ChatMensaje />
          </div>
        )}
      </div>
    </>
  );
};

export default MensajesViews;
