import axios from 'axios';
import { useNavigate } from 'react-router';
import { ErrorToast } from '../helpers/NotificacionToast';



export const prospectosApi = axios.create({
    baseURL: 'https://backgonavi.gabocrm.com/',
});


prospectosApi.interceptors.request.use(
    (config) => {
        // Modificar la configuración de la solicitud antes de enviarla
        // Puedes agregar encabezados, tokens, etc.
        const token = localStorage.getItem('token')
        config.headers["Authorization"] = token;
        return config;
    }
);


prospectosApi.interceptors.response.use(
    (response) => {
        return response;
      },
    (error) => {
        // Manejar errores de respuesta
        if (error.response.status == 500) { ErrorToast('Server Internal Error (500)')}
        if (error.response.status == 0) { ErrorToast('Error de conexion, intente nuevamente')}
        if (error.response.status == 401) {
            localStorage.clear()
            window.location.href='/auth/'
            return
        }
        return Promise.reject(error);
    }
);
