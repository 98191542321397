import { useCallback, useState } from "react";
import { NavbarComponents } from "../components/NavbarComponents";
import { SidebarComponents } from "../components/SidebarComponents";
import { CoreRoutes } from "../routes/CoreRoutes";
import { SidebarMovilComponents } from "../components/SidebarMovilComponents";
import { TitleComponents } from "../components/TitleComponents";

export const CoreViews = () => {
  const [sidebar, setSidebar] = useState(false);

  const handleSidebar = useCallback(
    (status: boolean) => {
      setSidebar(status);
    },
    [sidebar]
  );
  return (
    <>
      <div>
        <SidebarMovilComponents status={sidebar} setSidebar={handleSidebar} />
        <SidebarComponents />
        <div className="lg:pl-72">
          <NavbarComponents setSidebar={handleSidebar} />
          <main className="py-10">
            <TitleComponents />
            <div className="px-4 sm:px-6 lg:px-8">
              <CoreRoutes />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
