import { createAsyncThunk } from "@reduxjs/toolkit";

import { initialStateUsuario } from "./UsuariosSlice";
import { prospectosApi } from "../../api/prospectosApi";

export const fetchUsuarioAsync = createAsyncThunk(
  "usuario",
  async (page?: number) => {
    let parametro = page ? `/api/users/?page=${page}` : `/api/users/`;

    return prospectosApi
      .get(parametro)
      .then((result) => {
        if (result.status == 200) {
          return result.data;
        }
        return initialStateUsuario.usuario;
      })
      .catch((err) => {
        return initialStateUsuario.usuario;
      });
  }
);
