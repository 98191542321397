import { createAsyncThunk } from "@reduxjs/toolkit";
import { prospectosApi } from "../../api/prospectosApi";
import queryString from "query-string";

export class paramsOpotunidadTable {
  search?: number;
  page?: number;
  prospect?: string;
}

export const oportunidadAsync = createAsyncThunk(
  "prospectos/",
  async () => {
    const params = queryString.parse(window.location.search);
    return prospectosApi
      .get(`/api/sales/opportunity/`, { params })
      .then((result) => {
        return {
          ...result.data,
          cliente: params.prospect ? params.prospect : "all",
        };
      })
      .catch((err) => {
        return [];
      });
  }
);

export const oportunidadIdAsync = createAsyncThunk(
  "oportunidad/id",
  async (id: number = 1) => {
    const url = `/api/sales/opportunity/${id}/`;
    return prospectosApi
      .get(url)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);
