

export const opcionIdetificacion = [
  {
    id: 1,
    name: "V",
  },
  {
    id: 2,
    name: "E",
  },
  {
    id: 3,
    name: "J",
  },
  {
    id: 4,
    name: "P",
  },
  {
    id: 5,
    name: "G",
  },
];


export const metodoDeEnvio=[
  {
    id:1,
    name:'Whatsapp'
  },
  {
    id:2,
    name:'SMS'
  },
  {
    id:3,
    name:'Correo'
  },
]

export const opcionesOportunidadPerson = [
  {
    id: 1,
    name: "Cliente",
    prospect:false
  },
  {
    id: 2,
    name: "Prospecto",
    prospect:true
  },
];
export const opcionesOportunidadService = [
  {
    id: 1,
    name: "Aereo",
    value: "air",
  },
  {
    id: 2,
    name: "Maritimo",
    value: "sea",
  },
];
// Si se agrega un nuevo pais tambien tiene que agregarse en assets/icons
export const opcionesOportunidadOriginDestino:any = [
  
  {
    id: 1,
    name: "China",
    icon:'cn'
  },
  {
    id: 2,
    name: "España",
    icon:'es'
  },
  {
    id: 3,
    name: "Miami",
    icon:'us'
  },
  {
    id: 4,
    name: "Venezuela",
    icon:'ve'
  },
];


export const ObtenerPais=(country:string):typeof opcionesOportunidadOriginDestino=>{
  const find =opcionesOportunidadOriginDestino.find((d : typeof opcionesOportunidadOriginDestino)=>d.name==country )
  return find
}
