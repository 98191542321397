import { Autocomplete, AutocompleteItem, Avatar } from "@nextui-org/react";
import {
  ObtenerPais,
  opcionesOportunidadOriginDestino,
} from "../../core/data/opcionesOportunidad";

export type Localizaciones = "Miami" | "China" | "España" | "Venezuela";

interface Props {
  country: Localizaciones;
  className?: string;
}

export const CountryIcon = ({ country, className }: Props) => {
  return (
    <>
      <Avatar
        alt={country}
        className={className}
        src={`https://flagcdn.com/${ObtenerPais(country).icon}.svg`}
      />
    </>
  );
};
