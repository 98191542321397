import { createSlice } from "@reduxjs/toolkit";

export interface initialSlide {
    status:boolean
}

const initialState:initialSlide ={
    status:false
}


export const slideDetalleSlice = createSlice({
    name: 'slideDetalle',
    initialState,
    reducers: {
        mostrarSlideDetalle: (state) => {
            state.status = true
        },
        ocultarSlideDetalle: (state) => {
            state.status = false
        }
    },
})


export const { mostrarSlideDetalle,ocultarSlideDetalle } = slideDetalleSlice.actions;