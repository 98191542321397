import { TablaMesajes } from "./components/TablaMesajes"
import { TabsMensajes } from "./components/TabsMensajes"
import { ToolbarMensajes } from "./components/ToolbarMensajes"



const MarketingViews = () => {
  return (
    <>
      <ToolbarMensajes/>
      <TabsMensajes/>
      <TablaMesajes/>
    </>
  )
}


export default MarketingViews