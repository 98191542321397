import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Button,
  User,
} from "@nextui-org/react";
import {
  EllipsisVerticalIcon,
  PaperAirplaneIcon,
  PlusIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { GmailIcon } from "../../assets/icons/GmailIcon";
import { getMyUser } from "../../helpers/myUser";
import { useDispatch } from "react-redux";
import { mostrarSlideCrear } from "../../store/slides/slideCrear";
import { useNavigate } from "react-router-dom";
import { ExitIcon } from "../../assets/icons/ExitIcon";

export const MenuDropDown = () => {
  const handleLogout=()=>{
    localStorage.clear()
    window.location.href='/auth/'
  }
  return (
    <>
      <Dropdown
        showArrow
        radius="sm"
        classNames={{
          base: "before:bg-default-200", // change arrow background
          content: "p-0 border-small border-divider bg-background",
        }}
      >
        <DropdownTrigger>
          <Button variant="light" disableRipple>
            <span className="sr-only">Open user menu</span>
            <UserIcon className="h-8 w-8 rounded-full bg-gray-50" />
            <span className="hidden lg:flex lg:items-center">
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                aria-hidden="true"
              >
                {getMyUser().name} {getMyUser().last_name}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Custom item styles"
          disabledKeys={["profile"]}
          className="p-3"
          itemClasses={{
            base: [
              "rounded-md",
              "text-default-500",
              "transition-opacity",
              "data-[hover=true]:text-foreground",
              "data-[hover=true]:bg-default-100",
              "dark:data-[hover=true]:bg-default-50",
              "data-[selectable=true]:focus:bg-default-50",
              "data-[pressed=true]:opacity-70",
              "data-[focus-visible=true]:ring-default-500",
            ],
          }}
        >
          <DropdownSection
            aria-label="Profile & Actions"
            showDivider
            title="Oportunidad"
          >
            <DropdownItem
              isReadOnly
              key="profile"
              className="h-14 gap-2 opacity-100"
            >
              <User
                name={`${getMyUser().name} ${getMyUser().last_name}`}
                description={getMyUser().email}
                classNames={{
                  name: "text-default-600",
                  description: "text-default-500",
                }}
                avatarProps={
                  <UserIcon className="h-8 w-8 rounded-full bg-gray-50" />
                }
              />
            </DropdownItem>
          </DropdownSection>

          {/* <DropdownSection
            aria-label="Preferences"
            showDivider
            title="Marketing"
          >
            <DropdownItem
              key="quick_search"
              onPress={() => (window.location.href = "/marketing/")}
            >
              Historial
            </DropdownItem>
            <DropdownItem
              key="correo"
              endContent={<GmailIcon className="text-large h-5 w-5" />}
            >
              Enviar Correo
            </DropdownItem>
            <DropdownItem
              key="mensaje"
              endContent={<PaperAirplaneIcon className="text-large h-5 w-5" />}
            >
              Enviar Mensaje
            </DropdownItem>
          </DropdownSection>

          <DropdownSection
            aria-label="Help & Feedback"
            title="Usuario"
            showDivider
          >
            <DropdownItem
              key="user"
              endContent={<PlusIcon className="text-large h-5 w-5" />}
            >
              Crear usuario
            </DropdownItem>
          </DropdownSection> */}
          <DropdownSection aria-label="Help & Feedback">
            <DropdownItem
              key="logout"
              onPress={handleLogout}
              endContent={<ExitIcon className="h-6 w-6 mr-3" />}
            >
              Salir
            </DropdownItem>
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
