import { createSlice } from "@reduxjs/toolkit";

export interface initialSlideCrear {
    status:boolean
    cliente:boolean
}

const initialState:initialSlideCrear ={
    status:false,
    cliente:false
}


export const slideCrearSlice = createSlice({
    name: 'slideCrear',
    initialState,
    reducers: {
        mostrarSlideCrear: (state,{payload}) => {
            state.status = true
            state.cliente = payload
        },
        ocultarSlideCrear: (state) => {
            state.status = false
        }
    },
})


export const { mostrarSlideCrear,ocultarSlideCrear } = slideCrearSlice.actions;