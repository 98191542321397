import { InformationCircleIcon } from '@heroicons/react/20/solid'

export const AlertMensajes = () => {
    return (
        <div className="rounded-full border mx-7 bg-blue-50 p-4 shadow-sm">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">Introduce los  detalles para enviar el mensaje masivo</p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                
              </p>
            </div>
          </div>
        </div>
      )
}
