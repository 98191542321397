import { Pagination } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { SelectorInterface } from "../../../../interfaces/SelectorInterface";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { fetchMarketingAsync } from "../../../../store/marketing/thunks";

export const PaginatorMensajes = () => {
  const { count } = useSelector((d: SelectorInterface) => d.marketing);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const handlePageChange = async (e: any) => {
    const rutaAnterior = queryString.parse(window.location.search);
    const parse = {
      ...rutaAnterior,
      page: e,
    };
    const params = queryString.stringify(parse);
    const url = `/marketing/?${params}`;
    await navigate(url);
    await dispatch(fetchMarketingAsync());
  };
  return (
    <>
      <div className="flex justify-end py-3 mr-10">
        <Pagination
          isCompact
          color="secondary"
          size="lg"
          onChange={handlePageChange}
          showControls
          total={Math.ceil(count / 10)}
          initialPage={1}
        />
      </div>
    </>
  );
};
