import { configureStore } from "@reduxjs/toolkit";
import { slideDetalleSlice } from "./slides/slideDetalles";
import { slideCrearSlice } from "./slides/slideCrear";
import { modalAddPaquetesSlice } from "./modals/ModalAddPaquetes";
import { modalMensajeria } from "./modals/ModalCorreoMensajeria";
import { slideUsuarioSlice } from "./slides/slideUsuario";
import { usuarioSlice } from "./usuarios/UsuariosSlice";
import { oportunidadSlice } from "./oportunidad/oportunidadSlice";
import { oportunidadIDSlice } from "./oportunidad/oportunidadIDSlice";
import { marketingSlice } from "./marketing/marketingSlice";

export const store = configureStore({
    reducer: {
        slideDetalle:slideDetalleSlice.reducer,
        slideCrear:slideCrearSlice.reducer,
        modalAddPaquetes:modalAddPaquetesSlice.reducer,
        modalMensajeria:modalMensajeria.reducer,
        slideUsuario:slideUsuarioSlice.reducer,
        usuario:usuarioSlice.reducer,
        oportunidad:oportunidadSlice.reducer,
        oportunidadID:oportunidadIDSlice.reducer,
        marketing:marketingSlice.reducer
    },
})