import { toast } from "react-toastify"

const inicial = 'Coloca un texto para mostrar'

export const ErrorToast = (texto: string = inicial) => {
    toast.error(texto)
}
export const SuccessTast = (texto: string = inicial) => {
    toast.success(texto)
}
export const WarningToast = (texto: string = inicial) => {
    toast.warning(texto)
}
export const PromesaToast = () => {
    const id = toast.loading("cargando")
    const success=(texto: string = inicial)=>{
        toast.update(id, { render: texto, type: "success", isLoading: false,autoClose:2000 })
    }
    const error=(texto: string = inicial)=>{
        toast.update(id, { render: texto, type: "error", isLoading: false,autoClose:2000 })
    }

    return {
        success,
        error
    }
}

