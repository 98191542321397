import { createSlice } from "@reduxjs/toolkit";

type accion = "crear" | "actualizar" | "oportunidad" | "prospecto" | null;

export interface initialModalAddPaquetes {
  status: boolean;
  type: accion;
  paquetesProspecto: any;
  paquetesOportunidad: any;
  package: {
    id: number
    sale_opportunity: number
    description: string
    price: number
    currency: number
    quantity: number
    weight: any
    length: number
    width: number
    height: number
    tax: any
    tax2: any
    created_by: number
    updated_by: any
    created_at: number
}
}

const initialState: initialModalAddPaquetes = {
  status: false,
  type: null,
  paquetesProspecto: [],
  paquetesOportunidad: [],
  package: {
    id: 1,
    sale_opportunity: 1,
    description: '',
    price: 0,
    currency: 0,
    quantity: 0,
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    tax: 0,
    tax2: 0,
    created_by: 0,
    updated_by: 0,
    created_at: 0,
},
};

export const modalAddPaquetesSlice = createSlice({
  name: "modalAddPaquetes",
  initialState,
  reducers: {
    mostrarModalAddPaquetes: (state, { payload }) => {
      state.status = true;
      state.type = payload;
    },
    ocultarModalAddPaquetes: (state) => {
      state.status = false;
      state.type = null;
    },
    selectedPackage:(state,{payload})=>{
      state.type = 'actualizar'
      state.package = payload
      state.status = true;
    },
    eliminarPaquetesProspecto: (state, { payload }) => {
      state.paquetesProspecto = payload;
    },
    eliminarPaquetesOportunidad: (state, { payload }) => {
      state.paquetesOportunidad = payload
    },
    addPaquetesOportunidad: (state, { payload }) => {
      state.paquetesOportunidad = [...state.paquetesOportunidad, payload];
      state.status = false;
    },
    addPaquetesProspecto: (state, { payload }) => {
      state.paquetesProspecto = [...state.paquetesProspecto, payload];
      state.status = false;
    },
  },
});

export const {
  mostrarModalAddPaquetes,
  ocultarModalAddPaquetes,
  eliminarPaquetesProspecto,
  eliminarPaquetesOportunidad,
  addPaquetesOportunidad,
  addPaquetesProspecto,
  selectedPackage
} = modalAddPaquetesSlice.actions;
