import { createSlice } from "@reduxjs/toolkit";
import { oportunidadIdAsync } from "./thunks";

import { Message } from "yup";
import { Sale, SalesOpportunityDetail, StateSliceOportunidad } from "../../interfaces/OportunindadIDinterfaces";

export interface initialStateOportunidadID {
    isLoading: boolean
    OportunidadId: StateSliceOportunidad
    messages:Message[]
    SalesOpportunityDetail:SalesOpportunityDetail[]
    sale:Sale | false
}

const initialStateOportunidad: initialStateOportunidadID = {
    isLoading: false,
    OportunidadId: {
        id: 0,
        client: {
            id: 0,
            name: "",
            last_name: "",
            email: "",
            identification: "",
            phone: "",
            prospect: false,
            created_by: null,
            updated_by: 0
        },
        status: 0,
        status_name: "",
        service: "",
        origin: "",
        destination: "",
        date: "",
        created_by: 0,
    },
    messages:[],
    SalesOpportunityDetail:[],
    sale:false
}



export const oportunidadIDSlice = createSlice({
    name: 'oportunidad',
    initialState: initialStateOportunidad,
    reducers: {
        oportunindadSliceFuncion: (state, { payload }) => {
            state.isLoading = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(oportunidadIdAsync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(oportunidadIdAsync.fulfilled, (state, {payload}) => {
                const {messages,sales_opportunity_details,sale,...data} = payload
                state.sale = sale??false
                state.messages = messages
                state.SalesOpportunityDetail = sales_opportunity_details
                state.OportunidadId=data
                state.isLoading = false;                
            });
    },
})

export const { oportunindadSliceFuncion } = oportunidadIDSlice.actions;

