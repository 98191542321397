import {
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/solid";
import { GmailIcon } from "../../../../assets/icons/GmailIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { classNames } from "../../../../helpers/className";

export const TabsMensajes = () => {
  const activo: string = "text-secondary border-b-2 border-secondary";
  const inactivo: string =
    "border-transparent rounded-t-lg hover:text-secondary hover:border-secondary";
  const refParam = new URLSearchParams(window.location.search).get("message_via");
  //await dispatch(oportunidadAsync());
  const navigation = useNavigate();
  const dispatch = useDispatch<any>();

  const handleNavigate = async (url: string) => {
    window.location.href = url;
  };
  return (
    <div className="border-b border-gray-200  mb-3">
      <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 ">
        <li className="me-2">
          <a
            href="/marketing/"
            className={classNames(
              !refParam ? activo : inactivo,
              "inline-flex items-center justify-center p-4 border-b-2   group"
            )}
          >
            <ArrowPathIcon className="w-4 h-4 me-2 text-gray-400 group-hover:text-secondary " />
            Todos
          </a>
        </li>
        <li className="me-2">
          <a
            href="/marketing/?message_via=1"
            className={classNames(
              refParam == "1" ? activo : inactivo,
              "inline-flex items-center justify-center p-4 border-b-2   group"
            )}
          >
            <ChatBubbleLeftRightIcon className="w-4 h-4 me-2 text-gray-400 group-hover:text-secondary " />
            Whatsapp
          </a>
        </li>
        <li className="me-2">
          <a
            href="/marketing/?message_via=2"
            className={classNames(
              refParam == "2" ? activo : inactivo,
              "inline-flex items-center justify-center p-4 border-b-2   group"
            )}
          >
            <ChatBubbleLeftRightIcon className="w-4 h-4 me-2 text-gray-400 group-hover:text-secondary " />
            SMS
          </a>
        </li>
        <li className="me-2">
          <a
            href="/marketing/?message_via=3"
            className={classNames(
              refParam == "3" ? activo : inactivo,
              "inline-flex items-center justify-center p-4 border-b-2   group"
            )}
            aria-current="page"
          >
            <GmailIcon className="w-4 h-4 me-2 text-secondary" />
            Correo
          </a>
        </li>
      </ul>
    </div>
  );
};
