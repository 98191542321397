import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OportunidadId } from "../../interfaces/OportunindadIDinterfaces";
import { oportunidadAsync } from "./thunks";

export interface InitialStateOportunidad {
  page: number;
  cliente: string;
  count: number;
  oportunidades: any[];
  isLoading: boolean;
  firstPage: boolean;
}
const initialState: InitialStateOportunidad = {
  page: 1,
  cliente: "all",
  count: 0,
  oportunidades: [],
  isLoading: false,
  firstPage: false,
};

export const oportunidadSlice = createSlice({
  name: "oportunidad",
  initialState,
  reducers: {
    startLoadingOportunidad: (state /* action */) => {
      state.isLoading = true;
    },
    firstPagePagination(state) {
      state.firstPage = !state.firstPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(oportunidadAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(oportunidadAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.oportunidades = action.payload.results;
        state.count = action.payload.count;
        state.cliente = action.payload.cliente;
      });
  },
});

export const { startLoadingOportunidad, firstPagePagination } =
  oportunidadSlice.actions;
