import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { RutasPrincipal } from "./routes/RutasPrincipal";
import { store } from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProviders } from "./context/AuthProviders";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <AuthProviders>
          <BrowserRouter>
            <RutasPrincipal />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </BrowserRouter>
        </AuthProviders>
      </Provider>
    </>
  );
};
export default App;
