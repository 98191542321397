import { createSlice } from "@reduxjs/toolkit";
import { Usuario } from "../../interfaces/UsuariosInterface";

export interface initialSlideUsuario {
    status:boolean
    actualizar:boolean
    usuarioID:Usuario |false
    
}

const initialState:initialSlideUsuario ={
    status:false,
    actualizar:false,
    usuarioID:false
}


export const slideUsuarioSlice = createSlice({
    name: 'slideUsuario',
    initialState,
    reducers: {
        mostrarSlideUsuario: (state,{payload}) => {
            state.status = true
            state.actualizar=payload
        },
        ocultarSlideUsuario: (state) => {
            state.status = false
            state.actualizar=false
            state.usuarioID=false
        },
        setUsuarioId:(state,{payload})=>{
            state.usuarioID = payload
        }
    },
})


export const { mostrarSlideUsuario,ocultarSlideUsuario,setUsuarioId } = slideUsuarioSlice.actions;