import { Navigate, Route, Routes } from "react-router-dom";
import { AuthPage } from "../auth/page/AuthPage";
import { CoreViews } from '../core/Page/CorePage';
import { RutaPrivada } from "./RutaPrivada";
import { RutaPublica } from "./RutaPublica";

export const RutasPrincipal = () => {
  return (
    <>
      <Routes>
        <Route
          path="auth/"
          element={
            <RutaPublica>
              <Routes>
                <Route path="/*" element={<AuthPage />} />
              </Routes>
            </RutaPublica>
          }
        />
        <Route path="/*" element={
            <RutaPrivada>
              <CoreViews/>
            </RutaPrivada>
        } />
      </Routes>
    </>
  );
};
