import { createAsyncThunk } from "@reduxjs/toolkit";
import { prospectosApi } from "../../api/prospectosApi";
import queryString from "query-string";

export const fetchMarketingAsync = createAsyncThunk(
  "marketing",
  async () => {
    const params = queryString.parse(window.location.search);
    return prospectosApi
      .get(`/api/services/messages/`,{params})
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);
