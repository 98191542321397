import {
    FolderIcon,
    HomeIcon,
    UserIcon,
    UserGroupIcon,
    MegaphoneIcon
} from "@heroicons/react/24/outline";

export const navigation = [
    { name: "Oportunidad", href: "oportunidad/", icon: UserGroupIcon, children: false },
    { name: "Marketing", href: "marketing/", icon: MegaphoneIcon, children: true },
    { name: "Usuario", href: "usuario/", icon: UserIcon, children: false },
];