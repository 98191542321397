import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { navigation } from "../data/menu";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router';
import queryString from "query-string";


function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
export const SidebarComponents = () => {

  
  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 pb-4 bg-primary">
          <div className="flex h-16 shrink-0 items-center border-b px-5">
            <img
              className="h-10 w-full"
              src={require("../../assets/img/Logotipo4@4x.png")}
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col px-4">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        to={item.href}
                        end
                        className={({ isActive }) =>
                          classNames(
                            isActive? "bg-gray-50 text-primary": "text-gray-100 hover:text-primary hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold group"
                          )
                        }
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              {/* <li className="mt-auto">
                <a
                  href="#"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                >
                  <Cog6ToothIcon
                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  Settings
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
