export const handleInputChange = (e:any) => {
    // Filtrar y permitir solo dígitos

    
    const numerosValidos = e.replace(/\D/g, ''); // Expresión regular para eliminar no dígitos
    return numerosValidos
};



export const  formatearFecha=(fechaString: string): string =>{
    
    const fecha = new Date(fechaString);
    
    const anio = fecha.getFullYear().toString(); // Obtiene los últimos dos dígitos del año
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // El mes es indexado desde 0
    const dia = fecha.getDate().toString().padStart(2, '0');
  
    return `${anio}-${mes}-${dia}`;
  }