import { useParams } from "react-router";

export const TitleComponents = () => {
  const obtenerSegmento = (ruta: string) => {
    const segmentos = ruta.split("/").filter(Boolean); // Elimina segmentos vacíos
    if (segmentos.length === 0) {
      return null; // No hay segmentos
    }
    return segmentos[segmentos.length - 1]; // Devuelve el último segmento
  };
  const nombreRuta: any = useParams();
  const titulo: string = nombreRuta["*"];
  return (
    <div className="max-w-7xl  px-4 sm:px-4 lg:px-4 mb-7 lg:mb-10">
      <h1 className="text-4xl font-bold leading-tight tracking-tight text-gray-900 capitalize">
        {nombreRuta["*"].length==0? 'Oportunidad': obtenerSegmento(nombreRuta["*"])}
      </h1>
    </div>
  );
};
