import { createSlice } from "@reduxjs/toolkit";
import { fetchMarketingAsync } from "./thunks";
import { Message } from "../../interfaces/OportunindadIDinterfaces";


export interface MarketingInterfaces {
  isLoading: boolean;
  messages: Message[];
  count: number;
  firstPage: boolean;
  page: number;
}

export const initialStateMarketing: MarketingInterfaces = {
  isLoading: false,
  messages: [],
  count: 0,
  firstPage: true,
  page: 1,
};

export const marketingSlice = createSlice({
  name: "marketing",
  initialState: initialStateMarketing,
  reducers: {
    startLoadingMarketing: (state) => {
      state.isLoading = true;
    },
    nexPageMarketing: (state, action) => {
      state.page = action.payload;
    },
    firstPageMarketing: (state) => {
      state.firstPage = !state.firstPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketingAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMarketingAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messages = action.payload.results;
        state.count = action.payload.count;
      });
  },
});

// Action creators are generated for each case reducer function
export const { startLoadingMarketing, firstPageMarketing, nexPageMarketing } =
  marketingSlice.actions;
