import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MarketingViews from "../views/marketing/MarketingViews";
import MensajesViews from "../views/marketing/mensajes/MensajesViews";
import { CorreoViews } from "../views/marketing/correo/CorreoViews";
import { Spinner } from "@nextui-org/react";

const LazyOportunidad = lazy(
  () =>
    import(
      /* webpackChunkName: "LazyPage1" */ "../views/oportunidad/OportunidadView"
    )
);
const LazyMarketing = lazy(
  () =>
    import(
      /* webpackChunkName: "LazyPage1" */ "../views/marketing/MarketingViews"
    )
);
const LazyUsuario = lazy(
  () =>
    import(/* webpackChunkName: "LazyPage1" */ "../views/usuario/UsuarioViews")
);

export const CoreRoutes = () => {
  return (
    <>
      <div>
        <Suspense
          fallback={
            <div className="flex justify-center items-center">
              <Spinner size="lg" color="secondary" />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Navigate to="oportunidad/" />} />
            <Route path="oportunidad/" element={<LazyOportunidad />} />
            <Route path="marketing/">
              <Route index element={<MarketingViews />} />
              <Route path="mensajes" element={<MensajesViews />} />
              <Route path="correo" element={<CorreoViews />} />
            </Route>
            <Route path="usuario/" element={<LazyUsuario />} />
            {/* <Route path='/*' element={<Navigate to='oportunidad/' />} /> */}
          </Routes>
        </Suspense>
      </div>
    </>
  );
};
