// import { Button } from "primereact/button";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "@nextui-org/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { authApi } from "../../api/authApi";
import { ErrorToast, SuccessTast } from "../../helpers/NotificacionToast";
import { AuthContext } from "../../context/AuthProviders";

const schema = yup
  .object({
    email: yup.string().required("*Este campo es obligatorio*"),
    password: yup.string().required("*Este campo es obligatorio*"),
  })
  .required();

export const FormAuth = () => {
  const [cargando, setCargando] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { setToken } = useContext(AuthContext);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    if (!data.password) return console.log("error");
    setCargando(true);
    authApi
      .post("/login/", data)
      .then(({ data }: any) => {
        SuccessTast("Acceso exitoso");
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("token", `Token ${data.token}`);
        setCargando(false);
        setToken(data.token);
      })
      .catch(({ response }: any) => {
        setCargando(false);
        if (response.status == 500)
          return ErrorToast("Server Internal Error (500)");
        if (response.status == 0)
          return ErrorToast("Error de conexion intente nuevamente");
        ErrorToast(response.data.error);
      });
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="mt-2">
          <Input
            type="email"
            label="Usuario"
            id="Email"
            isInvalid={!!errors.email}
            errorMessage={errors.email && "*Este campo es obligatorio*"}
            {...register("email")}
          />
        </div>
      </div>

      <div>
        <div className="mt-2">
          <Input
            label="Contraseña"
            id="Contraseña"
            isInvalid={!!errors.password}
            errorMessage={errors.password && "*Este campo es obligatorio*"}
            endContent={
              <button
                className="focus:outline-none "
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeIcon className="text-2xl text-default-400 pointer-events-none h-5 w-5" />
                ) : (
                  <EyeSlashIcon className="text-2xl text-default-400 pointer-events-none h-5 w-5" />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
            className="w-full"
            {...register("password")}
          />
        </div>
      </div>

      <div>
        <Button
          color="secondary"
          className="w-full"
          type="submit"
          size="lg"
          isLoading={cargando}
        >
          Ingresar
        </Button>
      </div>
    </form>
  );
};
